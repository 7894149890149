import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import React from "react";
import { Event } from "../../../model/Event";
import { Product, ProductType } from "../../../model/Product";
import { ApiBackend } from "../../../providers/apibackend";
import ClearableSelect from "../ClearableSelect";
import { GroupedProducts, ProductListChangeEvent, buildChangeEvent, getGroupedProducts } from "./utils";

interface IProps {
    id?: string;
    variant?: "filled" | "outlined" | "standard";
    initialValue?: string | string[];
    showArchived?: boolean;
    productTypes?: ProductType[];
    filterEventId?: string;
    useVariants?: boolean;
    autoSelectFirst?: boolean;
    clearable?: boolean;
    multiple?: boolean;

    onInit?: (evt: ProductListChangeEvent) => void;
    onClear?: () => void;
    onChange: (evt: ProductListChangeEvent) => void;
}

interface IState {
    value?: string[];
    products?: Product[];
    groupedProducts?: { name: string; products: Product[]; }[];
}

class ProductList extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.api = new ApiBackend();

        this.state = {
            value: undefined,
            products: undefined,
            groupedProducts: undefined,
        };
    }

    async componentDidMount(): Promise<void> {
        await this.init();
    }

    async componentDidUpdate(prevProps: IProps) {
        if (prevProps.showArchived !== this.props.showArchived) {
            await this.init();
        }
    }

    render(): JSX.Element {
        const { variant, clearable, multiple } = this.props;
        const { value, groupedProducts } = this.state;

        let safeValue: string | string[] = !!groupedProducts && value ? value : [];
        if (!multiple) {
            safeValue = safeValue.length > 0 ? safeValue[0] : "";
        }

        return (
            <ClearableSelect
                clearable={clearable}
                multiple={multiple}
                variant={variant}
                value={safeValue}
                onClear={this.handleClear}
                onChange={this.handleChange}
            >
                {this.itemsFromProducts(groupedProducts)}
            </ClearableSelect>
        );
    }

    private init = async () => {
        const { showArchived, autoSelectFirst, initialValue, useVariants } = this.props;

        const work: (Promise<Product[]> | Promise<Event[]>)[] = [
            this.api.listProducts(showArchived),
            this.api.listEvents()
        ];

        const [products, events] = await Promise.all(work);
        const groupedProducts = this.getGroupedProducts(products as Product[], events as Event[]);


        let nextValue: string[] = [];
        if (autoSelectFirst) {
            if (useVariants) {
                groupedProducts[0].products[0].variants[0].Id;
            } else {
                nextValue = [groupedProducts[0].products[0].id];
            }
        } else if (initialValue) {
            if (!Array.isArray(initialValue)) {
                nextValue = [initialValue];
            } else {
                nextValue = initialValue;
            }
        }

        this.setState({
            products: products as Product[],
            groupedProducts: groupedProducts,
            value: nextValue
        }, () => {
            this.notifyInit();
        });
    };

    private getGroupedProducts = (products: Product[], events: Event[]): GroupedProducts[] => {
        const { productTypes, filterEventId } = this.props;

        return getGroupedProducts(products, events, productTypes, filterEventId);
    };

    private itemsFromProducts = (products: GroupedProducts[]): any[] => {
        if (!products || products.length === 0) {
            return null;
        }

        const { multiple, useVariants } = this.props;
        const { value } = this.state;

        const toggleProducts = (s: { name: string; products: Product[]; }) => {
            if (!multiple) {
                return;
            }

            let nextValue: string[] = [];
            let ids: string[] = [];

            if (useVariants) {
                s.products.forEach(p => {
                    const pVariantIds = p.variants?.map(v => v.Id);
                    ids.push(...pVariantIds);
                });
            } else {
                ids = s.products.map(p => p.id);
            }

            if (ids.every(p => value.includes(p))) {
                nextValue = (value as string[]).filter((p) => !ids.includes(p));
            } else {
                const toAdd = ids.filter((p) => !(value as string[]).includes(p));
                nextValue = [...(value as string[]), ...toAdd];
            }

            this.setState({ value: nextValue }, () => {
                this.notifyChange();
            });
        };

        const toggleVariants = (p: Product) => {
            if (!multiple) {
                return;
            }

            if (!p.variants) {
                return;
            }

            let nextValue: string[] = [];
            const variantIds = p.variants.map(v => v.Id);
            if (variantIds.every(v => value.includes(v))) {
                nextValue = (value as string[]).filter((v) => !variantIds.includes(v));
            } else {
                const toAdd = variantIds.filter((v) => !(value as string[]).includes(v));
                nextValue = [...(value as string[]), ...toAdd];
            }

            this.setState({ value: nextValue }, () => {
                this.notifyChange();
            });
        };

        const getOptions = (products: Product[]) => {
            let options: any[];

            if (useVariants) {
                options = products.map(p => {
                    if (p.variants?.length === 1) {
                        return (
                            <MenuItem key={p.variants[0].Id} value={p.variants[0].Id} style={{ marginLeft: 18 }}>
                                {p.variants[0].Name}
                            </MenuItem>
                        );
                    }

                    const innerOptions = p.variants?.map(v => {
                        return (
                            <MenuItem key={v.Id} value={v.Id} style={{ marginLeft: 27 }}>
                                {v.Name}
                            </MenuItem>
                        );
                    });

                    return [
                        <ListSubheader disableSticky style={{ marginLeft: 18, cursor: multiple ? "pointer" : "default" }}>
                            <span
                                style={{ display: "block", width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    toggleVariants(p);
                                }}
                            >
                                {p.name}
                            </span>
                        </ListSubheader>,
                        innerOptions
                    ];
                });

            } else {
                options = products.map(p => {
                    return (
                        <MenuItem key={p.id} value={p.id} style={{ marginLeft: 18 }}>
                            {p.name}
                        </MenuItem>
                    );
                });
            }

            return options;
        };

        const listItems = products.map(s => {
            return [
                <ListSubheader key={s.name} disableSticky style={{ cursor: multiple ? "pointer" : "default" }}>
                    <span
                        style={{ display: "block", width: "100%" }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleProducts(s);
                        }}
                    >
                        {s.name}
                    </span>
                </ListSubheader>,
                getOptions(s.products)
            ];
        });

        return listItems;
    };

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let nextValue: string | string[] = event.target.value;
        if (!Array.isArray(nextValue)) {
            nextValue = [nextValue];
        }

        this.setState({ value: nextValue }, () => {
            this.notifyChange();
        });
    };

    private handleClear = (): void => {
        const { onClear } = this.props;

        this.setState({ value: [] }, () => {
            if (onClear) {
                onClear();
            }
        });
    };

    private notifyInit = (): void => {
        const { onInit } = this.props;

        if (!onInit) {
            return;
        }

        onInit(this.buildChangeEvent());
    };

    private notifyChange = (): void => {
        const { onChange } = this.props;

        onChange(this.buildChangeEvent());
    };

    private buildChangeEvent = () => {
        const { useVariants } = this.props;
        const { products, value } = this.state;
        return buildChangeEvent(useVariants, products, value);
    };
}

export default ProductList;
